<template>
  <div class="main">
    <transition name="slide-fade" mode="out-in">
      <router-view v-if="isPoliciesRoute"/>
      <Maintenance v-else-if="underMaintenance"/>
      <ActivationPayment v-else-if="isLoggedIn && !$getActive() && !(isRoot || isSupport || isReSeller)"/>
      <router-view v-else-if="isLoggedIn"/>
      <Login v-else/>
    </transition>
  </div>
</template>

<script>
const Login = () => import(/* webpackChunkName: "login" */ '@/views/Login/index.vue');
const Maintenance = () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance/index.vue');
const ActivationPayment = () => import(/* webpackChunkName: "billing" */ '@/views/Settings/Billing/Payment/ActivationPayment.vue');

import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: "App",

  components: {
    Login,
    Maintenance,
    ActivationPayment
  },

  data() {
    return {
      underMaintenance: false,
    }
  },

  computed: {
    isPoliciesRoute() {
      return this.$route.path.startsWith('/policies');
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    permissions() {
      if ( !localStorage.getItem('user') || localStorage.getItem('user') === "" )
        return false;

      const user = JSON.parse(localStorage.getItem('user'));
      if ( !user || !user.permissions )
        return null;

      return user.permissions;
    },

    isRoot() {
      const permissions = this.permissions;
      return permissions ? permissions.isRoot : false;
    },

    isSupport() {
      const permissions = this.permissions;
      return permissions ? permissions.isSupport : false;
    },

    isReSeller() {
      const permissions = this.permissions;
      return permissions ? permissions.isReSeller : false;
    }
  },

  methods: {
    ...mapActions([
      'logout'
    ]),
  },

  created() {
    axios.interceptors.response.use(undefined, err => {
      if ( (err.response.status === 401) && err.config && !err.config.__isRetryRequest ) {
        this.logout();
        this.$router.push({ name: 'Login', query: { do: 'login' } });
      }
      return Promise.reject(err);
    });
  }
}

</script>

<style lang="scss">
  @import "./sass/main.scss";
</style>